import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import {attendanceReducer} from "../app/modules/Attendance/redux/attendanceReducer";
import {customersSlice} from "../app/modules/ECommerce/_redux/customers/customersSlice";
import {invitationReducer} from "../app/modules/Invitation/redux/invitationReducer";
import {productsSlice} from "../app/modules/ECommerce/_redux/products/productsSlice";
import {remarksSlice} from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
import {specificationsSlice} from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  attendance: attendanceReducer,
  customers: customersSlice.reducer,
  invitation: invitationReducer,
  products: productsSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer
});