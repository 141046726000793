export const INVITATION = {
  EVENT_RETRIEVE_REQUEST: 'INVITATION_EVENT_RETRIEVE_REQUEST',
  EVENT_RETRIEVE_SUCCESS: 'INVITATION_EVENT_RETRIEVE_SUCCESS',
  EVENT_RETRIEVE_ERROR: 'INVITATION_EVENT_RETRIEVE_ERROR',
  EVENT_RETRIEVE_RESET: 'INVITATION_EVENT_RETRIEVE_RESET',

  SET_RESPONSE_REQUEST: 'INVITATION_SET_RESPONSE_REQUEST',
  SET_RESPONSE_SUCCESS: 'INVITATION_SET_RESPONSE_SUCCESS',
  SET_RESPONSE_ERROR: 'INVITATION_SET_RESPONSE_ERROR',
  SET_RESPONSE_RESET: 'INVITATION_SET_RESPONSE_RESET',

  PE_RETRIEVE_REQUEST: 'INVITATION_PE_RETRIEVE_REQUEST',
  PE_RETRIEVE_SUCCESS: 'INVITATION_PE_RETRIEVE_SUCCESS',
  PE_RETRIEVE_ERROR: 'INVITATION_PE_RETRIEVE_ERROR',
  PE_RETRIEVE_RESET: 'INVITATION_PE_RETRIEVE_RESET',

  SET_PE_RESPONSE_REQUEST: 'INVITATION_SET_PE_RESPONSE_REQUEST',
  SET_PE_RESPONSE_SUCCESS: 'INVITATION_SET_PE_RESPONSE_SUCCESS',
  SET_PE_RESPONSE_ERROR: 'INVITATION_SET_PE_RESPONSE_ERROR',
  SET_PE_RESPONSE_RESET: 'INVITATION_SET_PE_RESPONSE_RESET',

  VR_RETRIEVE_REQUEST: 'INVITATION_VR_RETRIEVE_REQUEST',
  VR_RETRIEVE_SUCCESS: 'INVITATION_VR_RETRIEVE_SUCCESS',
  VR_RETRIEVE_ERROR: 'INVITATION_VR_RETRIEVE_ERROR',
  VR_RETRIEVE_RESET: 'INVITATION_VR_RETRIEVE_RESET',

  SET_VR_RESPONSE_REQUEST: 'INVITATION_SET_VR_RESPONSE_REQUEST',
  SET_VR_RESPONSE_SUCCESS: 'INVITATION_SET_VR_RESPONSE_SUCCESS',
  SET_VR_RESPONSE_ERROR: 'INVITATION_SET_VR_RESPONSE_ERROR',
  SET_VR_RESPONSE_RESET: 'INVITATION_SET_VR_RESPONSE_RESET',
}