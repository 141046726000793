import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import styled from "styled-components";
import moment from "moment";
import * as invitationAction from "../redux/invitationAction";

const CONTAINER = styled.div`
  .error {
    border: 1px solid #ff6565;
  }
`;

export function InvitationVrPage(props) {
  const dispatch = useDispatch();
  const invitationReducer = useSelector(state => state.invitation);
  const [dataVR, setDataVR] = useState({});
  const [responseInvitation, setResponseInvitation] = useState(null);

  useEffect(() => {
    let idVR = props.location.search.substring(4);
    dispatch(invitationAction.vrRetrieveRequest({id: idVR}));
  }, []);

  useEffect(() => {
    if(invitationReducer.isLoading === false && invitationReducer.isError === false && invitationReducer.invitationVR !== null){
      setDataVR(invitationReducer.invitationVR.data.assignedVolunteer);
    }
    if(invitationReducer.isLoading === false && invitationReducer.isError === false && invitationReducer.invitationVRSetResponse !== null){
      dispatch(invitationAction.setVrResponseReset());
      let idVR = props.location.search.substring(4);
      dispatch(invitationAction.vrRetrieveRequest({id: idVR}));
    }
  }, [invitationReducer]);

  function setResponse(param){
    setResponseInvitation(param);
    let payload = {
      id: props.location.search.substring(4),
      status: param
    }
    dispatch(invitationAction.setVrResponseRequest(payload));
  }

  return (
    <div
      className="d-flex align-item-center justify-content-center"
      style={{
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          height: "100vh",
          width: "100vw",
          backgroundImage: `url(${toAbsoluteUrl("/media/sccfsc/bg-volunteer.jpeg")})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          filter: "blur(8px)",
          position: "absolute",
        }}
      />
      <Card style={{ width: "50rem" }}>
        <CardHeader>
          <div className="col-lg-12 justify-content-center align-items-center d-flex">
            <div className="col-lg-2">
              <img
                alt="Logo"
                src={toAbsoluteUrl("/media/sccfsc/sccfsc-logo.png")}
                style={{
                  maxHeight: "45px",
                }}
              />
            </div>
            <div className="col-lg-8">
              <h5 className="text-center ">Invitation Volunteer Request</h5>
            </div>
            <div className="col-lg-2"/>
          </div>
        </CardHeader>
        <CardBody>
          <CONTAINER>
            {Object.keys(dataVR).length !== 0 ? (
              <Form>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={6}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        Volunteer Request Name
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={6}>
                    <Form.Group>
                      <Form.Label>{dataVR?.volunteerRequest?.name}</Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={6}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        Start Date
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={6}>
                    <Form.Group>
                      <Form.Label>
                        {moment(dataVR?.volunteerRequest?.startDate).format("DD MMMM YYYY")+' '+dataVR?.volunteerRequest?.startTime?.substring(0,5)}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={6}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        End Date
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={6}>
                    <Form.Group>
                      <Form.Label>
                        {moment(dataVR?.volunteerRequest?.endDate).format("DD MMMM YYYY")+' '+dataVR?.volunteerRequest?.endTime?.substring(0,5)}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={6}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        Frequency Type
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={6}>
                    <Form.Group>
                      <Form.Label>
                        {dataVR?.volunteerRequest?.frequency}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={6}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        Assigned Volunteer Status
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={6}>
                    <Form.Group>
                      <Form.Label>
                        {dataVR?.volunteer?.status}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                {
                  dataVR?.volunteer?.status === "Contacted" || dataVR?.volunteer?.status === "Selected" ?
                  <Form.Group
                    as={Row}
                    className={"mb-1 align-items-center justify-content-center"}
                  >
                    <Button
                      variant={"success"}
                      style={{ width: "30%", marginRight: 10 }}
                      onClick={() => setResponse("Accepted")}
                      disabled={invitationReducer.isLoading}
                    >
                      Accept
                      {
                        invitationReducer.isLoading && responseInvitation === "Accepted" &&
                        <span className="ml-3 spinner spinner-white"></span>
                      }
                    </Button>
                    <Button
                      variant={"danger"}
                      style={{ width: "30%", marginRight: 10 }}
                      onClick={() => setResponse("Rejected")}
                      disabled={invitationReducer.isLoading}
                    >
                      Rejected
                      {
                        invitationReducer.isLoading && responseInvitation === "Rejected" &&
                        <span className="ml-3 spinner spinner-white"></span>
                      }
                    </Button>
                  </Form.Group> :
                  null
                }
              </Form>
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            )}
          </CONTAINER>
        </CardBody>
      </Card>
    </div>
  );
}
