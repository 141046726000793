import { put, takeLatest, call, all } from 'redux-saga/effects';
import * as actionType from './actionType';
import * as attendanceAction from './attendanceAction';
import * as attendanceRepo from '../../../datasources/attendanceRepo';

function* eventRetrieve(data){
  try {
    const res = yield call(attendanceRepo.apiAttendanceEventRetrieve, data.payload);
    yield put(attendanceAction.eventRetrieveSuccess(res));
  } catch(err) {
    yield put(attendanceAction.eventRetrieveError(err));
  }
}

function* validateVr(data){
  try {
    const res = yield call(attendanceRepo.apiAttendanceValidate, data.payload);
    yield put(attendanceAction.validateVrSuccess(res));
  } catch(err) {
    if(err.response?.data){
      yield put(attendanceAction.validateVrError(err.response?.data));
    } else {
      yield put(attendanceAction.validateVrError(err));
    }
  }
}

function* checkinVr(data){
  try {
    const res = yield call(attendanceRepo.apiAttendanceCheckinVr, data.payload);
    yield put(attendanceAction.checkinVrSuccess(res));
  } catch(err) {
    if(err.response?.data){
      yield put(attendanceAction.checkinVrError(err.response?.data));
    } else {
      yield put(attendanceAction.checkinVrError(err));
    }
  }
}

function* checkoutVr(data){
  try {
    const res = yield call(attendanceRepo.apiAttendanceCheckoutVr, data.payload);
    yield put(attendanceAction.checkoutVrSuccess(res));
  } catch(err) {
    if(err.response?.data){
      yield put(attendanceAction.checkoutVrError(err.response?.data));
    } else {
      yield put(attendanceAction.checkoutVrError(err));
    }
  }
}

export default function* watchAttendance() {
  yield all([
    takeLatest(actionType.ATTENDANCE.EVENT_RETRIEVE_REQUEST, eventRetrieve),
    takeLatest(actionType.ATTENDANCE.VALIDATE_VR_REQUEST, validateVr),
    takeLatest(actionType.ATTENDANCE.CHECKIN_VR_REQUEST, checkinVr),
    takeLatest(actionType.ATTENDANCE.CHECKOUT_VR_REQUEST, checkoutVr)
  ])
}