import * as actionType from './actionType';

export const eventRetrieveRequest = data => ({
  type: actionType.ATTENDANCE.EVENT_RETRIEVE_REQUEST,
  payload: data
})
export const eventRetrieveSuccess = data => ({
  type: actionType.ATTENDANCE.EVENT_RETRIEVE_SUCCESS,
  payload: data
})
export const eventRetrieveError = data => ({
  type: actionType.ATTENDANCE.EVENT_RETRIEVE_ERROR,
  payload: data
})
export const eventRetrieveReset = data => ({
  type: actionType.ATTENDANCE.EVENT_RETRIEVE_RESET,
  payload: data
})

export const validateVrRequest = data => ({
  type: actionType.ATTENDANCE.VALIDATE_VR_REQUEST,
  payload: data
})
export const validateVrSuccess = data => ({
  type: actionType.ATTENDANCE.VALIDATE_VR_SUCCESS,
  payload: data
})
export const validateVrError = data => ({
  type: actionType.ATTENDANCE.VALIDATE_VR_ERROR,
  payload: data
})
export const validateVrReset = data => ({
  type: actionType.ATTENDANCE.VALIDATE_VR_RESET,
  payload: data
})

export const checkinVrRequest = data => ({
  type: actionType.ATTENDANCE.CHECKIN_VR_REQUEST,
  payload: data
})
export const checkinVrSuccess = data => ({
  type: actionType.ATTENDANCE.CHECKIN_VR_SUCCESS,
  payload: data
})
export const checkinVrError = data => ({
  type: actionType.ATTENDANCE.CHECKIN_VR_ERROR,
  payload: data
})
export const checkinVrReset = data => ({
  type: actionType.ATTENDANCE.CHECKIN_VR_RESET,
  payload: data
})

export const checkoutVrRequest = data => ({
  type: actionType.ATTENDANCE.CHECKOUT_VR_REQUEST,
  payload: data
})
export const checkoutVrSuccess = data => ({
  type: actionType.ATTENDANCE.CHECKOUT_VR_SUCCESS,
  payload: data
})
export const checkoutVrError = data => ({
  type: actionType.ATTENDANCE.CHECKOUT_VR_ERROR,
  payload: data
})
export const checkoutVrReset = data => ({
  type: actionType.ATTENDANCE.CHECKOUT_VR_RESET,
  payload: data
})