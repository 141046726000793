import { put, takeLatest, call, all } from 'redux-saga/effects';
import * as actionType from './actionType';
import * as invitationAction from './invitationAction';
import * as invitationRepo from '../../../datasources/invitationRepo';

function* eventRetrieve(data){
  try {
    const res = yield call(invitationRepo.apiInvitationEventRetrieve, data.payload);
    yield put(invitationAction.eventRetrieveSuccess(res));
  } catch(err) {
    yield put(invitationAction.eventRetrieveError(err));
  }
}

function* setResponse(data){
  try {
    const res = yield call(invitationRepo.apiInvitationSetResponse, data.payload);
    yield put(invitationAction.setResponseSuccess(res));
  } catch(err) {
    yield put(invitationAction.setResponseError(err));
  }
}

function* peRetrieve(data){
  try {
    const res = yield call(invitationRepo.apiInvitationPeRetrieve, data.payload);
    yield put(invitationAction.peRetrieveSuccess(res));
  } catch(err) {
    yield put(invitationAction.peRetrieveError(err));
  }
}

function* setPeResponse(data){
  try {
    const res = yield call(invitationRepo.apiInvitationSetPeResponse, data.payload);
    yield put(invitationAction.setPeResponseSuccess(res));
  } catch(err) {
    yield put(invitationAction.setPeResponseError(err));
  }
}

function* vrRetrieve(data){
  try {
    const res = yield call(invitationRepo.apiInvitationVrRetrieve, data.payload);
    yield put(invitationAction.vrRetrieveSuccess(res));
  } catch(err) {
    yield put(invitationAction.vrRetrieveError(err));
  }
}

function* setVrResponse(data){
  try {
    const res = yield call(invitationRepo.apiInvitationSetVrResponse, data.payload);
    yield put(invitationAction.setVrResponseSuccess(res));
  } catch(err) {
    yield put(invitationAction.setVrResponseError(err));
  }
}

export default function* watchInvitation() {
  yield all([
    takeLatest(actionType.INVITATION.EVENT_RETRIEVE_REQUEST, eventRetrieve),
    takeLatest(actionType.INVITATION.SET_RESPONSE_REQUEST, setResponse),
    takeLatest(actionType.INVITATION.PE_RETRIEVE_REQUEST, peRetrieve),
    takeLatest(actionType.INVITATION.SET_PE_RESPONSE_REQUEST, setPeResponse),
    takeLatest(actionType.INVITATION.VR_RETRIEVE_REQUEST, vrRetrieve),
    takeLatest(actionType.INVITATION.SET_VR_RESPONSE_REQUEST, setVrResponse),
  ])
}