import {all} from "redux-saga/effects";
import * as auth from "../app/modules/Auth/_redux/authRedux";
import watchAttendance from "../app/modules/Attendance/redux/attendanceSaga";
import watchInvitation from "../app/modules/Invitation/redux/invitationSaga";

export function* rootSaga() {
  yield all([
    auth.saga(),
    watchInvitation(),
    watchAttendance()
  ]);
}