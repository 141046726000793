import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import styled from "styled-components";
import moment from "moment-timezone";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as attendanceAction from "../redux/attendanceAction";

const CONTAINER = styled.div`
  .error {
    border: 1px solid #ff6565;
  }
`;

const validationSchema = Yup.object().shape({
  identity: Yup.string()
    .required('Field is required')
})

export function AttendanceVrPage(props) {
  const dispatch = useDispatch();
  const attendanceReducer = useSelector(state => state.attendance);
  const [idAttendanceVr, setIdAttendanceVr] = useState(null);
  const [identity, setIdentity] = useState(null);
  const [idAssignedVolunteer, setIdAssignedVolunteer] = useState(null);
  const [idAttendance, setIdAttendance] = useState(null);
  const [dataVR, setDataVR] = useState({});
  const [dataCheckin, setDataCheckin] = useState(null);
  const [isCheckIn, setIsCheckIn] = useState(null);
  const { register, handleSubmit, setError, formState:{ errors } } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      identity: ''
    }
  });

  useEffect(() => {
    let idVR = props.location.search.substring(4);
    dispatch(attendanceAction.eventRetrieveRequest({id: idVR}));
    setIdAttendanceVr(idVR);
  }, []);

  useEffect(() => {
    if(attendanceReducer.isLoading === false && attendanceReducer.isError === false && attendanceReducer.attendanceEvent !== null){
      setDataVR(attendanceReducer.attendanceEvent.data.volunteerRequest);
    }
    if(attendanceReducer.isLoading === false && attendanceReducer.isError === true){
      setError("identity", {
        type: "manual",
        message: attendanceReducer.errorMsg.message
      });
      setIsCheckIn(null);
    }
    if(attendanceReducer.isLoading === false && attendanceReducer.isError === false && attendanceReducer.validateVr !== null){
      setIsCheckIn(attendanceReducer.validateVr?.data?.checkIn);
      setIdAssignedVolunteer(attendanceReducer.validateVr?.data?.assignedVolunteerId);
      if(attendanceReducer.validateVr?.data?.checkIn === false){
        setIdAttendance(attendanceReducer.validateVr?.data?.attendanceId);
      }
    }
    if(attendanceReducer.isLoading === false && attendanceReducer.isError === false && attendanceReducer.checkinVr !== null){
      setIsCheckIn(false);
      setDataCheckin(attendanceReducer.checkinVr);
      dispatch(attendanceAction.checkinVrReset());
      let payload = {
        id: idAttendanceVr,
        identity: identity
      }
      dispatch(attendanceAction.validateVrRequest(payload));
    }
  }, [attendanceReducer, isCheckIn]);

  function submitAttendance(values){
    if(isCheckIn === null){
      let payload = {
        id: idAttendanceVr,
        identity: values.identity
      }
      dispatch(attendanceAction.validateVrRequest(payload));
      setIdentity(values.identity);
    } else {
      dispatch(attendanceAction.checkinVrReset());
      dispatch(attendanceAction.checkoutVrReset());
      if(isCheckIn === true){
        dispatch(attendanceAction.checkinVrRequest({id: idAttendanceVr, assignedVolunteerId: idAssignedVolunteer}));
      } else if(isCheckIn === false){
        dispatch(attendanceAction.checkoutVrRequest({id: idAttendanceVr, attendanceId: idAttendance}));
      }
    }
  }

  return (
    <div
      className="d-flex align-item-center justify-content-center"
      style={{
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          height: "100vh",
          width: "100vw",
          backgroundImage: `url(${toAbsoluteUrl("/media/sccfsc/bg-volunteer.jpeg")})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          filter: "blur(8px)",
          position: "absolute",
        }}
      />
      <Card style={{ width: "50rem" }}>
        <CardHeader>
          <div className="col-lg-12 justify-content-center align-items-center d-flex">
            <div className="col-lg-2">
              <img
                alt="Logo"
                src={toAbsoluteUrl("/media/sccfsc/sccfsc-logo.png")}
                style={{
                  maxHeight: "45px",
                }}
              />
            </div>
            <div className="col-lg-8">
              <h5 className="text-center ">Attendance Volunteer</h5>
            </div>
            <div className="col-lg-2"/>
          </div>
        </CardHeader>
        <CardBody>
          <CONTAINER>
            {Object.keys(dataVR).length !== 0 ? (
              <Form onSubmit={handleSubmit(submitAttendance)}>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={5}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        Volunteer Request Name
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={6}>
                    <Form.Group>
                      <Form.Label>{dataVR?.name}</Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={5}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        Start Date
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={7}>
                    <Form.Group>
                      <Form.Label>
                        {moment(dataVR?.startDate).tz("Asia/Singapore").format("DD MMMM YYYY")+" "+dataVR?.startTime.substring(0,5)}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={5}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        End Date
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={7}>
                    <Form.Group>
                      <Form.Label>
                      {moment(dataVR?.endDate).tz("Asia/Singapore").format("DD MMMM YYYY")+" "+dataVR?.endTime.substring(0,5)}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={5}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        Frequency Type
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={7}>
                    <Form.Group>
                      <Form.Label>
                        {dataVR?.frequency}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className={"mb-1"}>
                  <Col lg={4} xs={5}>
                    <Form.Group>
                      <Form.Label className={"font-weight-bold"}>
                        Status
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6} xs={7}>
                    <Form.Group>
                      <Form.Label>
                        {dataVR?.status}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Form.Group>
                {
                  dataVR?.status === "Started" ?
                  <>
                    {
                      attendanceReducer.checkoutVr === null && dataCheckin !== null &&
                      <Form.Group className={'col-lg-6 mx-auto d-flex justify-context-center align-items-center flex-column mb-0'}>
                        {
                          dataCheckin?.data?.message + ' '+ moment(dataCheckin?.data?.checkinTime).tz("Asia/Singapore").format("DD MMMM YYYY HH:mm")
                        }
                      </Form.Group>
                    }
                    {
                      attendanceReducer.checkoutVr !== null &&
                      <Form.Group className={'col-lg-6 mx-auto d-flex justify-context-center align-items-center flex-column mb-0'}>
                        {
                          attendanceReducer.checkoutVr?.data?.message + ' '+ moment(attendanceReducer.checkoutVr?.data?.checkOutTime).tz("Asia/Singapore").format("DD MMMM YYYY HH:mm")
                        }
                      </Form.Group>
                    }
                    <Form.Group className={'col-lg-6 mx-auto d-flex justify-context-center align-items-center flex-column mb-0'}>
                      <Form.Control 
                        type="text" 
                        placeholder="email or mobile" 
                        {...register("identity")}
                        className={(errors?.identity) && "error"}
                      />
                      <Form.Text className={`${errors.identity ? 'text-danger' : 'text-muted'} mt-0 mb-0`}>
                        {
                          errors.identity?.message
                        }
                      </Form.Text>
                    </Form.Group>
                    <Form.Group className={`col-lg-6 mx-auto d-flex justify-context-center align-items-center flex-column 
                      ${errors?.identity ? 'mt-0' : 'mt-5'}`}>
                        {
                          attendanceReducer.checkoutVr !== null ? null : 
                          <Button 
                            style={{ width: "100%"}} 
                            variant={`${isCheckIn === null ? 'primary' : isCheckIn === true ? 'success' : 'warning'}`} 
                            disabled={attendanceReducer.isLoading} 
                            type="submit"
                          >
                            {
                              isCheckIn === null ? 'SUBMIT' :
                              isCheckIn === true ? 'CHECK IN' : 'CHECK OUT'
                            }
                            {
                              attendanceReducer.isLoading &&
                              <span className="ml-3 spinner spinner-white"></span>
                            }
                          </Button>
                        }
                    </Form.Group>
                  </>
                  :
                  null
                }
              </Form>
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            )}
          </CONTAINER>
        </CardBody>
      </Card>
    </div>
  );
}
