import { methodService, apiService } from './apiService';

export const URL = {
  BASE_VR: '/volunteerRequests',
  BASE_ATTENDANCE: '/attendances'
}

export function apiAttendanceEventRetrieve(data) {
  return apiService(
    URL.BASE_VR+`/${data.id}`,
    methodService.GET,
    null,
    null
  );
}

export function apiAttendanceValidate(data) {
  return apiService(
    URL.BASE_ATTENDANCE+`/${data.id}/verify`,
    methodService.POST,
    data,
    null
  );
}

export function apiAttendanceCheckinVr(data) {
  return apiService(
    URL.BASE_ATTENDANCE+`/${data.id}/checkin`,
    methodService.POST,
    data,
    null
  );
}

export function apiAttendanceCheckoutVr(data) {
  return apiService(
    URL.BASE_ATTENDANCE+`/${data.id}/checkout`,
    methodService.PATCH,
    data,
    null
  );
}