import * as actionType from './actionType';

const initialState = {
  isLoading: false,
  isError: false,
  errorMsg: null,
  invitationEvent: null,
  invitationSetResponse: null,
  invitationPE: null,
  invitationPESetResponse: null,
  invitationVR: null,
  invitationVRSetResponse: null
}

const eventRetrieveRequest = state => ({
  ...state,
  isLoading: true
})
const eventRetrieveSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  invitationEvent: payload
})
const eventRetrieveError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const eventRetrieveReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  invitationEvent: null
})

const setResponseRequest = state => ({
  ...state,
  isLoading: true
})
const setResponseSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  invitationSetResponse: payload
})
const setResponseError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const setResponseReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  invitationSetResponse: null
})

const peRetrieveRequest = state => ({
  ...state,
  isLoading: true
})
const peRetrieveSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  invitationPE: payload
})
const peRetrieveError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const peRetrieveReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  invitationPE: null
})

const setPeResponseRequest = state => ({
  ...state,
  isLoading: true
})
const setPeResponseSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  invitationPESetResponse: payload
})
const setPeResponseError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const setPeResponseReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  invitationPESetResponse: null
})

const vrRetrieveRequest = state => ({
  ...state,
  isLoading: true
})
const vrRetrieveSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  invitationVR: payload
})
const vrRetrieveError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const vrRetrieveReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  invitationVR: null
})

const setVrResponseRequest = state => ({
  ...state,
  isLoading: true
})
const setVrResponseSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  invitationVRSetResponse: payload
})
const setVrResponseError = (state, payload) => ({
  ...state,
  isLoading: false,
  isError: true,
  errorMsg: payload
})
const setVrResponseReset = state => ({
  ...state,
  isLoading: false,
  isError: false,
  errorMsg: null,
  invitationVRSetResponse: null
})

export const invitationReducer = (state = initialState, action) => {
  switch (action.type ) {
    case actionType.INVITATION.EVENT_RETRIEVE_REQUEST:
      return eventRetrieveRequest(state, action.payload);
    case actionType.INVITATION.EVENT_RETRIEVE_SUCCESS:
      return eventRetrieveSuccess(state, action.payload);
    case actionType.INVITATION.EVENT_RETRIEVE_ERROR:
      return eventRetrieveError(state, action.payload);
    case actionType.INVITATION.EVENT_RETRIEVE_RESET:
      return eventRetrieveReset(state, action.payload);

    case actionType.INVITATION.SET_RESPONSE_REQUEST:
      return setResponseRequest(state, action.payload);
    case actionType.INVITATION.SET_RESPONSE_SUCCESS:
      return setResponseSuccess(state, action.payload);
    case actionType.INVITATION.SET_RESPONSE_ERROR:
      return setResponseError(state, action.payload);
    case actionType.INVITATION.SET_RESPONSE_RESET:
      return setResponseReset(state, action.payload);

    case actionType.INVITATION.PE_RETRIEVE_REQUEST:
      return peRetrieveRequest(state, action.payload);
    case actionType.INVITATION.PE_RETRIEVE_SUCCESS:
      return peRetrieveSuccess(state, action.payload);
    case actionType.INVITATION.PE_RETRIEVE_ERROR:
      return peRetrieveError(state, action.payload);
    case actionType.INVITATION.PE_RETRIEVE_RESET:
      return peRetrieveReset(state, action.payload);

    case actionType.INVITATION.SET_PE_RESPONSE_REQUEST:
      return setPeResponseRequest(state, action.payload);
    case actionType.INVITATION.SET_PE_RESPONSE_SUCCESS:
      return setPeResponseSuccess(state, action.payload);
    case actionType.INVITATION.SET_PE_RESPONSE_ERROR:
      return setPeResponseError(state, action.payload);
    case actionType.INVITATION.SET_PE_RESPONSE_RESET:
      return setPeResponseReset(state, action.payload);
  
    case actionType.INVITATION.VR_RETRIEVE_REQUEST:
      return vrRetrieveRequest(state, action.payload);
    case actionType.INVITATION.VR_RETRIEVE_SUCCESS:
      return vrRetrieveSuccess(state, action.payload);
    case actionType.INVITATION.VR_RETRIEVE_ERROR:
      return vrRetrieveError(state, action.payload);
    case actionType.INVITATION.VR_RETRIEVE_RESET:
      return vrRetrieveReset(state, action.payload);

    case actionType.INVITATION.SET_VR_RESPONSE_REQUEST:
      return setVrResponseRequest(state, action.payload);
    case actionType.INVITATION.SET_VR_RESPONSE_SUCCESS:
      return setVrResponseSuccess(state, action.payload);
    case actionType.INVITATION.SET_VR_RESPONSE_ERROR:
      return setVrResponseError(state, action.payload);
    case actionType.INVITATION.SET_VR_RESPONSE_RESET:
      return setVrResponseReset(state, action.payload);

    default:
      return state;
  }
};