import { methodService, apiService } from './apiService';

export const URL = {
  BASE_INVITATION: '/orientations',
  BASE_PARTICIPANT: '/participants',
  BASE_VOLUNTEER: '/assignedVolunteers'
}

export function apiInvitationEventRetrieve(data) {
  return apiService(
    URL.BASE_INVITATION+`/${data.id}`,
    methodService.GET,
    null,
    null
  );
}

export function apiInvitationSetResponse(data) {
  return apiService(
    URL.BASE_INVITATION+`/${data.id}/status`,
    methodService.PATCH,
    data,
    null
  )
}

export function apiInvitationPeRetrieve(data) {
  return apiService(
    URL.BASE_PARTICIPANT+`/${data.id}`,
    methodService.GET,
    null,
    null
  )
}

export function apiInvitationSetPeResponse(data) {
  return apiService(
    URL.BASE_PARTICIPANT+`/${data.id}/status`,
    methodService.PATCH,
    data,
    null
  )
}

export function apiInvitationVrRetrieve(data) {
  return apiService(
    URL.BASE_VOLUNTEER+`/${data.id}`,
    methodService.GET,
    null,
    null
  )
}

export function apiInvitationSetVrResponse(data) {
  return apiService(
    URL.BASE_VOLUNTEER+`/${data.id}`,
    methodService.PATCH,
    data,
    null
  )
}