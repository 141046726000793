export const ATTENDANCE = {
  EVENT_RETRIEVE_REQUEST: 'ATTENDANCE_EVENT_RETRIEVE_REQUEST',
  EVENT_RETRIEVE_SUCCESS: 'ATTENDANCE_EVENT_RETRIEVE_SUCCESS',
  EVENT_RETRIEVE_ERROR: 'ATTENDANCE_EVENT_RETRIEVE_ERROR',
  EVENT_RETRIEVE_RESET: 'ATTENDANCE_EVENT_RETRIEVE_RESET',

  VALIDATE_VR_REQUEST: 'ATTENDANCE_VALIDATE_VR_REQUEST',
  VALIDATE_VR_SUCCESS: 'ATTENDANCE_VALIDATE_VR_SUCCESS',
  VALIDATE_VR_ERROR: 'ATTENDANCE_VALIDATE_VR_ERROR',
  VALIDATE_VR_RESET: 'ATTENDANCE_VALIDATE_VR_RESET',

  CHECKIN_VR_REQUEST: 'ATTENDANCE_CHECKIN_VR_REQUEST',
  CHECKIN_VR_SUCCESS: 'ATTENDANCE_CHECKIN_VR_SUCCESS',
  CHECKIN_VR_ERROR: 'ATTENDANCE_CHECKIN_VR_ERROR',
  CHECKIN_VR_RESET: 'ATTENDANCE_CHECKIN_VR_RESET',

  CHECKOUT_VR_REQUEST: 'ATTENDANCE_CHECKOUT_VR_REQUEST',
  CHECKOUT_VR_SUCCESS: 'ATTENDANCE_CHECKOUT_VR_SUCCESS',
  CHECKOUT_VR_ERROR: 'ATTENDANCE_CHECKOUT_VR_ERROR',
  CHECKOUT_VR_RESET: 'ATTENDANCE_CHECKOUT_VR_RESET'
}