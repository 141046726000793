import * as actionType from './actionType';

export const eventRetrieveRequest = data => ({
  type: actionType.INVITATION.EVENT_RETRIEVE_REQUEST,
  payload: data
})
export const eventRetrieveSuccess = data => ({
  type: actionType.INVITATION.EVENT_RETRIEVE_SUCCESS,
  payload: data
})
export const eventRetrieveError = data => ({
  type: actionType.INVITATION.EVENT_RETRIEVE_ERROR,
  payload: data
})
export const eventRetrieveReset = data => ({
  type: actionType.INVITATION.EVENT_RETRIEVE_RESET,
  payload: data
})

export const setResponseRequest = data => ({
  type: actionType.INVITATION.SET_RESPONSE_REQUEST,
  payload: data
})
export const setResponseSuccess = data => ({
  type: actionType.INVITATION.SET_RESPONSE_SUCCESS,
  payload: data
})
export const setResponseError = data => ({
  type: actionType.INVITATION.SET_RESPONSE_ERROR,
  payload: data
})
export const setResponseReset = data => ({
  type: actionType.INVITATION.SET_RESPONSE_RESET,
  payload: data
})

export const peRetrieveRequest = data => ({
  type: actionType.INVITATION.PE_RETRIEVE_REQUEST,
  payload: data
})
export const peRetrieveSuccess = data => ({
  type: actionType.INVITATION.PE_RETRIEVE_SUCCESS,
  payload: data
})
export const peRetrieveError = data => ({
  type: actionType.INVITATION.PE_RETRIEVE_ERROR,
  payload: data
})
export const peRetrieveReset = data => ({
  type: actionType.INVITATION.PE_RETRIEVE_RESET,
  payload: data
})

export const setPeResponseRequest = data => ({
  type: actionType.INVITATION.SET_PE_RESPONSE_REQUEST,
  payload: data
})
export const setPeResponseSuccess = data => ({
  type: actionType.INVITATION.SET_PE_RESPONSE_SUCCESS,
  payload: data
})
export const setPeResponseError = data => ({
  type: actionType.INVITATION.SET_PE_RESPONSE_ERROR,
  payload: data
})
export const setPeResponseReset = data => ({
  type: actionType.INVITATION.SET_PE_RESPONSE_RESET,
  payload: data
})

export const vrRetrieveRequest = data => ({
  type: actionType.INVITATION.VR_RETRIEVE_REQUEST,
  payload: data
})
export const vrRetrieveSuccess = data => ({
  type: actionType.INVITATION.VR_RETRIEVE_SUCCESS,
  payload: data
})
export const vrRetrieveError = data => ({
  type: actionType.INVITATION.VR_RETRIEVE_ERROR,
  payload: data
})
export const vrRetrieveReset = data => ({
  type: actionType.INVITATION.VR_RETRIEVE_RESET,
  payload: data
})

export const setVrResponseRequest = data => ({
  type: actionType.INVITATION.SET_VR_RESPONSE_REQUEST,
  payload: data
})
export const setVrResponseSuccess = data => ({
  type: actionType.INVITATION.SET_VR_RESPONSE_SUCCESS,
  payload: data
})
export const setVrResponseError = data => ({
  type: actionType.INVITATION.SET_VR_RESPONSE_ERROR,
  payload: data
})
export const setVrResponseReset = data => ({
  type: actionType.INVITATION.SET_VR_RESPONSE_RESET,
  payload: data
})